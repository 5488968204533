import React, { useState, useEffect } from "react";
import { MAP_CATEGORIES_FILE_NAME } from "../constant";

// Định nghĩa kiểu dữ liệu của sản phẩm
interface Product {
  id: number;
  name: string;
  image_url: string;
  full_description: string;
}

// Định nghĩa các loại sản phẩm và tên hiển thị
const categories = [
  { id: "san-pham-chuyen-biet", name: "SẢN PHẨM CHUYÊN BIỆT" },
  { id: "keo-hotmelt-keo-nhiet", name: "KEO HOTMELT - KEO NHIỆT" },
  { id: "keo-sua-keo-goc-nuoc", name: "KEO SỮA - KEO GỐC NƯỚC" },
  { id: "keo-phun-keo-dung-moi", name: "KEO PHUN - KEO DUNG MÔI" },
  { id: "hoa-chat-phu-gia", name: "HÓA CHẤT & PHỤ GIA" },
  { id: "san-pham-khac", name: "SẢN PHẨM KHÁC" },
];

const ITEMS_PER_PAGE = 12; // Hiển thị tối đa 12 sản phẩm (4 sản phẩm x 3 hàng)

const ProductList: React.FC = () => {
  const [selectedCategory, setSelectedCategory] = useState(categories[0].id);
  const [products, setProducts] = useState<Product[]>([]);
  const [currentPage, setCurrentPage] = useState(1);

  // Hàm lấy dữ liệu từ file JSON theo danh mục
  const fetchProducts = async (category: string) => {
    const fileName = MAP_CATEGORIES_FILE_NAME.get(category);
    if (!fileName) return;

    try {
      const response = await import(`../data/${fileName}`);
      setProducts(response.default);
      setCurrentPage(1); // Đặt lại trang hiện tại khi thay đổi danh mục
    } catch (error) {
      console.error("Không thể tải sản phẩm:", error);
    }
  };

  // Lấy dữ liệu khi chọn danh mục mới
  useEffect(() => {
    fetchProducts(selectedCategory);
  }, [selectedCategory]);

  // Tính toán các sản phẩm hiển thị theo trang
  const displayedProducts = products.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  // Xử lý chuyển trang
  const totalPages = Math.ceil(products.length / ITEMS_PER_PAGE);
  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };
  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  return (
    <section className="flex flex-col md:flex-row container mx-auto py-10 min-h-screen">
      {/* Sidebar danh mục sản phẩm */}
      <aside className="md:w-1/4 p-4 bg-gray-100">
        <h3 className="text-xl font-bold text-custom-purple mb-4">Danh mục</h3>
        <ul className="space-y-2">
          {categories.map((category) => (
            <li
              key={category.id}
              className={`p-2 cursor-pointer rounded ${
                selectedCategory === category.id
                  ? "bg-custom-purple text-white"
                  : "text-gray-800 hover:bg-gray-200"
              }`}
              onClick={() => setSelectedCategory(category.id)}
            >
              {category.name}
            </li>
          ))}
        </ul>
      </aside>

      {/* Danh sách sản phẩm */}
      <div className="md:w-3/4 p-4 flex flex-col">
        <h2 className="text-2xl font-bold text-custom-purple mb-6">
          {categories.find((cat) => cat.id === selectedCategory)?.name}
        </h2>
        <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4 mb-8">
          {displayedProducts.map((product) => (
            <div
              key={product.id}
              className="bg-white p-4 rounded shadow-md flex flex-col items-center"
            >
              <img
                src={"/images/" + product.image_url}
                alt={product.name}
                className="w-full max-h-[200px] object-cover mb-4"
              />
              <h3 className="text-sm font-semibold text-custom-purple">
                {product.name}
              </h3>
            </div>
          ))}
        </div>

        {/* Điều hướng trang */}
        <div className="flex justify-center items-center space-x-4 mt-auto font-bold">
          <button
            onClick={handlePrevPage}
            disabled={currentPage === 1}
            className="px-4 py-2 bg-custom-purple text-white rounded disabled:opacity-50"
          >
            Trang trước
          </button>
          <span>
            Trang {currentPage} / {totalPages}
          </span>
          <button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
            className="px-4 py-2 bg-custom-purple text-white rounded disabled:opacity-50"
          >
            Trang sau
          </button>
        </div>
      </div>
    </section>
  );
};

export default ProductList;
