import React from "react";
import { Link } from "react-router-dom";

const CommitmentSection: React.FC = () => {
  return (
    <section className="bg-white py-16">
      <div className="container mx-auto flex flex-col md:flex-row items-center px-4 md:px-0">
        {" "}
        {/* Thêm px-4 cho phiên bản di động */}
        {/* Phần văn bản */}
        <div className="md:w-1/2">
          <h2 className="text-2xl font-bold text-custom-purple mb-6 text-center">
            CAM KẾT CỦA CHÚNG TÔI
          </h2>
          <ul className="list-disc list-inside text-gray-700 space-y-4">
            <li>
              Luôn nghiên cứu, cập nhật và ứng dụng những công nghệ, giải pháp
              mới nhất cho khách hàng.
            </li>
            <li>
              Luôn cung cấp cho khách hàng những giải pháp, sản phẩm phù hợp, có
              chất lượng cao và ổn định nhất.
            </li>
            <li>
              Luôn đồng hành với quý khách hàng trong bất kỳ tình huống nào; từ
              thử nghiệm, vận hành cho đến xử lý sự cố.
            </li>
            <li>Luôn có Phương án B, dự phòng trong mọi tình huống.</li>
            <li>Luôn lắng nghe, tiếp thu mọi ý kiến từ khách hàng.</li>
          </ul>
          <div className="mt-6 flex justify-center">
            <Link to="/gioi-thieu-ve-truong-thinh-chemicals">
              <button className="bg-red-600 text-white px-6 py-2 rounded-lg hover:bg-red-700 transition">
                XEM THÊM
              </button>
            </Link>
          </div>
        </div>
        {/* Phần hình ảnh */}
        <div className="md:w-1/2 mt-8 md:mt-0 md:ml-8">
          <img
            src="/commitment.png"
            alt="Commitment"
            className="rounded-lg shadow-lg"
          />
        </div>
      </div>
    </section>
  );
};

export default CommitmentSection;
