import React from "react";

const ComingSoon: React.FC = () => {
  return (
    <section className="flex items-center justify-center h-screen bg-gray-100">
      <div className="text-center">
        <h1 className="text-4xl md:text-6xl font-bold text-custom-purple mb-4">
          Sắp ra mắt
        </h1>
        <p className="text-lg md:text-xl text-gray-700">
          Chúng tôi đang chuẩn bị để mang đến cho bạn điều thú vị mới.
        </p>
      </div>
    </section>
  );
};

export default ComingSoon;
