import styled from "styled-components";

export const ProductContainer = styled.section`
  padding: 0.5rem 0;
  background-color: #f3f4f6;
  text-align: center;
`;

export const ProductTitle = styled.h2`
  color: #6600d6;
  margin-bottom: 1.5rem;
  font-size: 1.25rem;
  font-weight: bold;
  margin-top: 2rem;
`;

export const ProductBox = styled.div`
  background-color: #fff;
  padding: 0.5rem;
  border-radius: 0.5rem;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
`;

export const ProductImageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const ProductImage = styled.img`
  max-width: 100%;
  max-height: 200px;
  object-fit: cover;
  border-radius: 0.25rem;

  @media (min-width: 768px) {
    padding: 0.25rem;
  }
`;

export const ProductText = styled.h3`
  color: #6600d6;
  font-size: 1rem;
  margin-top: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (max-width: 768px) {
    font-size: 0.75rem;
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

export const SliderArrow = styled.button.attrs<{ mobileOpacity?: number }>({
  mobileOpacity: undefined,
})`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  background: none;

  &::before {
    content: "";
    display: inline-block;
    width: 10px;
    height: 10px;
    border: solid #6600d6; /* Màu đỏ cho mũi tên */
    border-width: 0 3px 3px 0;
  }

  &.slick-prev {
    left: -18px;
    transform: rotate(135deg);
  }

  &.slick-next {
    right: -18px;
    transform: rotate(-45deg);
  }

  /* Áp dụng opacity thấp hơn trên thiết bị di động */
  @media (max-width: 768px) {
    opacity: 0.5;
    left: ${({ className }) => (className === "slick-prev" ? "5px" : "auto")};
    right: ${({ className }) => (className === "slick-next" ? "5px" : "auto")};
    width: 28px;
    height: 28px;
  }
`;
