import React from "react";

const Introduction: React.FC = () => {
  return (
    <section className="relative w-full py-10 bg-white">
      <div className="container mx-auto px-4 md:px-0 text-left text-black max-w-4xl">
        {/* Phần tiêu đề */}
        <p className="text-sm md:text-base">
          <strong>
            <span className="text-custom-purple">
              CÔNG TY TNHH CÔNG NGHỆ HÓA CHẤT TRƯỜNG THỊNH
            </span>
          </strong>{" "}
          <em>
            <span className="text-custom-purple">
              (Trường Thịnh Chemicals – TTC)
            </span>
          </em>{" "}
          trân trọng gửi lời chào đến quý khách hàng và đối tác!
        </p>

        {/* Phần mô tả */}
        <p className="mt-4 text-sm md:text-base">
          Chúng tôi là{" "}
          <strong>
            <span className="text-custom-purple">
              NHÀ CUNG CẤP GIẢI PHÁP CHẤT KẾT DÍNH CHUYÊN NGHIỆP
            </span>
          </strong>{" "}
          hàng đầu.{" "}
          <em>
            <span className="text-custom-purple">
              Trường Thịnh Chemicals – TTC
            </span>
          </em>{" "}
          là chuyên gia về nghiên cứu, phát triển và cung cấp các giải pháp chất
          kết dính chuyên dụng. Từ đó, giúp khách hàng nâng cao chất lượng sản
          phẩm, tiết kiệm thời gian và chi phí sản xuất trong các ngành công
          nghiệp: sản xuất bao bì, sản xuất đồ gỗ, sản xuất băng keo v.v...
        </p>

        {/* Phần tiêu đề lý do */}
        <p className="pl-6 md:pl-10 mt-6">
          <span className="text-custom-purple text-lg md:text-xl">
            <strong>VÌ SAO BẠN NÊN LỰA CHỌN TTC</strong>
          </span>
        </p>

        {/* Danh sách lý do */}
        <ul className="list-disc list-inside pl-6 md:pl-10 mt-4 space-y-2 text-sm md:text-base">
          <li>
            <span className="text-custom-purple">GIAO HÀNG NHANH:</span> Thời
            gian giao hàng nhanh chóng và linh hoạt bằng nhiều phương thức
          </li>
          <li>
            <span className="text-custom-purple">HỖ TRỢ 24/7:</span> Tiếp nhận
            thông tin và phản hồi hỗ trợ kỹ thuật nhanh chóng thông qua Hotline:
            090 828 79 66
          </li>
          <li>
            <span className="text-custom-purple">CHẤT LƯỢNG ĐẢM BẢO:</span> Cung
            cấp sản phẩm có chất lượng ổn định và tuân thủ theo các tiêu chuẩn
            đã cam kết
          </li>
          <li>
            <span className="text-custom-purple">ĐỔI TRẢ LINH HOẠT:</span> Chính
            sách đổi / trả sản phẩm nhanh chóng với nhiều hình thức linh hoạt
          </li>
        </ul>

        {/* Phần tiêu đề cam kết */}
        <p className="pl-6 md:pl-10 mt-6">
          <span className="text-custom-purple text-lg md:text-xl">
            <strong>CAM KẾT CỦA TTC</strong>
          </span>
        </p>

        {/* Danh sách cam kết */}
        <ul className="list-disc list-inside pl-6 md:pl-10 mt-4 space-y-2 text-sm md:text-base">
          <li>
            <span className="text-custom-purple">LUÔN LUÔN:</span> Nghiên cứu,
            cập nhật và ứng dụng những công nghệ, giải pháp mới nhất cho khách
            hàng.
          </li>
          <li>
            <span className="text-custom-purple">LUÔN LUÔN:</span> Cung cấp cho
            khách hàng những giải pháp, sản phẩm phù hợp, có chất lượng cao và
            ổn định nhất.
          </li>
          <li>
            <span className="text-custom-purple">LUÔN LUÔN:</span> Đồng hành với
            quý khách hàng trong bất kỳ tình huống nào; từ thử nghiệm, vận hành
            cho đến xử lý sự cố.
          </li>
          <li>
            <span className="text-custom-purple">LUÔN LUÔN:</span> Có phương án
            B, dự phòng trong mọi tình huống.
          </li>
          <li>
            <span className="text-custom-purple">LUÔN LUÔN:</span> Lắng nghe,
            tiếp thu mọi ý kiến từ khách hàng.
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Introduction;
