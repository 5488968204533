import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header"; // Đảm bảo Layout chỉ chứa Header nếu cần
import Footer from "./components/Footer";
import HeroSection from "./components/HeroSection";
import StrengthSection from "./components/StrengthSection";
import ProductSection from "./components/ProductSection";
import CommitmentSection from "./components/CommitmentSection";
import Introduction from "./components/Introduction";
import RD from "./components/RD";
import Error from "./components/Error";
import GlobalStyle from "./GlobalStyle";
import ProductList from "./components/ProductList";
import Contact from "./components/Contacts";
import ComingSoon from "./components/ComingSoon";

const App: React.FC = () => {
  return (
    <>
      <GlobalStyle />
      <Router>
        <div className="flex flex-col min-h-screen">
          {/* Header cố định ở đầu */}
          <Header />

          {/* Phần nội dung chính */}
          <main className="flex-grow">
            <Routes>
              <Route
                path="/"
                element={
                  <>
                    <HeroSection />
                    <StrengthSection />
                    <ProductSection />
                    <CommitmentSection />
                  </>
                }
              />
              <Route
                path="/gioi-thieu-ve-truong-thinh-chemicals"
                element={<Introduction />}
              />
              <Route path="/san-pham" element={<ProductList />} />
              <Route path="/nghien-cuu-phat-trien" element={<RD />} />
              <Route path="/lien-he" element={<Contact />} />
              <Route path="/bai-viet" element={<ComingSoon />} />

              <Route path="*" element={<Error />} />
            </Routes>
          </main>

          {/* Footer cố định ở cuối */}
          <Footer />
        </div>
      </Router>
    </>
  );
};

export default App;
