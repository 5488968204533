import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

  :root {
    --custom-purple: #6600D6;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
  }

  .special-text {
    color: var(--custom-purple);
    text-transform: uppercase;
    font-weight: bold;
  }
`;

export default GlobalStyle;
