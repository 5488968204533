import React from "react";
import { FaFlask } from "react-icons/fa6"; // Import icon FaFlask

const HeroSection: React.FC = () => {
  return (
    <section className="relative w-full py-16 overflow-hidden">
      {/* Background Image */}
      <div
        className="absolute inset-0 bg-cover bg-center h-full"
        style={{
          backgroundImage: `url('/hero-background.jpg')`, // Path to background image
        }}
      ></div>
      {/* Overlay with custom-purple */}
      <div className="absolute inset-0 bg-custom-purple opacity-50 h-full"></div>

      {/* Top Curved Shape */}
      <div className="absolute top-0 left-0 w-full">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320"
          className="w-full"
        >
          <path
            fill="rgba(102, 0, 214, 0.5)"
            d="M0,64L48,80C96,96,192,128,288,144C384,160,480,160,576,144C672,128,768,96,864,106.7C960,117,1056,171,1152,186.7C1248,203,1344,181,1392,170.7L1440,160L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
          ></path>
        </svg>
      </div>

      {/* Bottom Curved Shape */}
      <div className="absolute bottom-0 left-0 w-full">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320"
          className="w-full"
        >
          <path
            fill="rgba(102, 0, 214, 0.5)"
            d="M0,160L48,170.7C96,181,192,203,288,224C384,245,480,267,576,256C672,245,768,203,864,181.3C960,160,1056,160,1152,165.3C1248,171,1344,181,1392,186.7L1440,192L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
          ></path>
        </svg>
      </div>

      {/* Hero Section Content */}
      <div className="relative z-10 container mx-auto text-center text-white">
        {/* Title */}
        <h1 className="text-2xl md:text-4xl font-extrabold uppercase mb-8 whitespace-nowrap">
          TRƯỜNG THỊNH CHEMICALS
        </h1>

        {/* Icon Row */}
        <div className="flex justify-center mb-8 space-x-4">
          <FaFlask className="text-[1.4rem] text-white" />
          <FaFlask className="text-[1.4rem] text-white" />
          <FaFlask className="text-[1.4rem] text-white" />
        </div>

        {/* Subtitle */}
        <p className="text-base md:text-lg font-bold uppercase mb-12">
          Nghiên cứu - Phát triển - Cung cấp
        </p>

        {/* Buttons */}
        <div className="mt-6 flex justify-center gap-4 flex-wrap">
          <button className="border border-white text-white text-sm px-5 py-2 rounded-lg hover:bg-white hover:text-custom-purple transition">
            Các Loại Keo Dán
          </button>
          <button className="border border-white text-white text-sm px-5 py-2 rounded-lg hover:bg-white hover:text-custom-purple transition">
            Hóa Chất & Phụ Gia
          </button>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
