import React from "react";

const Error: React.FC = () => {
  return (
    <section className="flex flex-col items-center justify-center h-screen bg-white text-center">
      {/* Số 404 với kích thước lớn, màu xám và độ mờ 50% */}
      <h1 className="text-9xl text-gray-500 opacity-50 font-bold mb-4">404</h1>

      {/* Thông báo lỗi */}
      <p className="text-xl md:text-2xl text-gray-700">
        Xin lỗi, chúng tôi không tìm thấy trang mà bạn cần!
      </p>
    </section>
  );
};

export default Error;
