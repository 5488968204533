export const MAP_CATEGORIES_FILE_NAME = new Map<string, string>([
  ["san-pham-chuyen-biet", "0.json"],
  ["hoa-chat-phu-gia", "1.json"],
  ["keo-sua-keo-goc-nuoc", "2.json"],
  ["keo-phun-keo-dung-moi", "3.json"],
  ["keo-hotmelt-keo-nhiet", "4.json"],
  ["san-pham-khac", "5.json"],
]);

export const MAP_CATEGORIES_CONTENT = new Map<number, string>([]);
