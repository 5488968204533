import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import specialData from "../data/0.json";
import waterBasedData from "../data/2.json";
import hotmeltData from "../data/4.json";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {
  ProductContainer,
  ProductTitle,
  ProductBox,
  ProductImageWrapper,
  ProductImage,
  ProductText,
  SliderArrow,
} from "./modules/Product.module";

interface Product {
  id: number;
  name: string;
  image_url: string;
}

const ProductSection: React.FC = () => {
  const [specialProducts, setSpecialProducts] = useState<Product[]>([]);
  const [hotmeltProducts, setHotmeltProducts] = useState<Product[]>([]);
  const [waterBasedProducts, setWaterBasedProducts] = useState<Product[]>([]);

  useEffect(() => {
    setSpecialProducts(specialData);
    setHotmeltProducts(hotmeltData);
    setWaterBasedProducts(waterBasedData);
  }, []);

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    nextArrow: <SliderArrow className="slick-next" />,
    prevArrow: <SliderArrow className="slick-prev" />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          arrows: true,
        },
      },
    ],
  };

  return (
    <ProductContainer>
      <div className="container mx-auto px-4 md:px-8">
        {/* SẢN PHẨM CHUYÊN BIỆT */}
        <ProductTitle style={{ marginTop: "2rem" }}>
          SẢN PHẨM CHUYÊN BIỆT
        </ProductTitle>
        <Slider {...sliderSettings}>
          {specialProducts.map((product) => (
            <div key={product.id} style={{ padding: "0.5rem" }}>
              <ProductBox>
                <ProductImageWrapper>
                  <ProductImage
                    src={"/images/" + product.image_url}
                    alt={product.name}
                  />
                </ProductImageWrapper>
                <ProductText>{product.name}</ProductText>
              </ProductBox>
            </div>
          ))}
        </Slider>

        {/* KEO HOTMELT - KEO NHIỆT */}
        <ProductTitle style={{ marginTop: "2rem" }}>
          KEO HOTMELT - KEO NHIỆT
        </ProductTitle>
        <Slider {...sliderSettings}>
          {hotmeltProducts.map((product) => (
            <div key={product.id} style={{ padding: "0.5rem" }}>
              <ProductBox>
                <ProductImageWrapper>
                  <ProductImage
                    src={"/images/" + product.image_url}
                    alt={product.name}
                  />
                </ProductImageWrapper>
                <ProductText>{product.name}</ProductText>
              </ProductBox>
            </div>
          ))}
        </Slider>

        {/* KEO SỮA - KEO GỐC NƯỚC */}
        <ProductTitle style={{ marginTop: "2rem" }}>
          KEO SỮA - KEO GỐC NƯỚC
        </ProductTitle>
        <Slider {...sliderSettings}>
          {waterBasedProducts.map((product) => (
            <div key={product.id} style={{ padding: "0.5rem" }}>
              <ProductBox>
                <ProductImageWrapper>
                  <ProductImage
                    src={"/images/" + product.image_url}
                    alt={product.name}
                  />
                </ProductImageWrapper>
                <ProductText>{product.name}</ProductText>
              </ProductBox>
            </div>
          ))}
        </Slider>
      </div>
    </ProductContainer>
  );
};

export default ProductSection;
