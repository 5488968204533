import React from "react";

const RD: React.FC = () => {
  return (
    <section className="relative w-full py-10 bg-white">
      <div className="container mx-auto px-4 md:px-0 text-left text-black max-w-4xl">
        {/* Tiêu đề */}
        <h2 className="text-custom-purple text-2xl md:text-3xl font-bold mb-4 md:mb-6">
          Trường Thịnh Chemicals (TTC)
        </h2>
        <p className="mb-4 text-base md:text-lg">
          cung cấp dịch vụ R&D, nghiên cứu & phát triển các loại keo dán đặc
          biệt theo yêu cầu của Khách Hàng.
        </p>

        {/* Danh sách dịch vụ R&D */}
        <ul className="list-disc list-inside space-y-2 text-sm md:text-base">
          <li>Khảo sát quy trình sản xuất của khách hàng;</li>
          <li>Lấy mẫu vật liệu;</li>
          <li>Tìm kiếm nguyên liệu phù hợp;</li>
          <li>
            Nghiên cứu, thiết lập công thức keo dán với đặc tính kỹ thuật theo
            yêu cầu;
          </li>
          <li>Thử mẫu & hiệu chỉnh;</li>
          <li>Cung cấp keo dán có giá thành phù hợp nhất đến khách hàng;</li>
          <li>Hướng dẫn sử dụng theo quy trình phù hợp;</li>
          <li>Hậu mãi và hỗ trợ kỹ thuật lâu dài.</li>
        </ul>

        {/* Liên hệ */}
        <p className="mt-6 text-sm md:text-base">
          <a href="#lien-he" className="text-custom-purple">
            Liên hệ
          </a>{" "}
          với chúng tôi để biết thêm chi tiết.
        </p>
      </div>
    </section>
  );
};

export default RD;
