import React from "react";
import { FaThumbsUp, FaShippingFast, FaHeadset } from "react-icons/fa"; // Sử dụng react-icons để hiển thị biểu tượng

const CommitmentSection: React.FC = () => {
  return (
    <section className="bg-white py-1">
      <div className="container mx-auto text-center">
        <div className="grid grid-cols-1 md:grid-cols-3">
          {/* Chất Lượng Đảm Bảo */}
          <div className="p-6">
            <div className="flex justify-center mb-4">
              <div className="border-2 border-custom-purple p-4 rounded-full">
                <FaThumbsUp className="text-custom-purple text-4xl" />{" "}
                {/* Biểu tượng */}
              </div>
            </div>
            <h3 className="text-custom-purple font-bold text-lg mb-2">
              CHẤT LƯỢNG ĐẢM BẢO
            </h3>
            <p className="text-gray-600">
              Sản phẩm có chất lượng ổn định và tuân thủ theo các tiêu chuẩn đã
              cam kết
            </p>
          </div>

          {/* Giao Hàng Nhanh */}
          <div className="p-6">
            <div className="flex justify-center mb-4">
              <div className="border-2 border-custom-purple p-4 rounded-full">
                <FaShippingFast className="text-custom-purple text-4xl" />{" "}
                {/* Biểu tượng */}
              </div>
            </div>
            <h3 className="text-custom-purple font-bold text-lg mb-2">
              GIAO HÀNG NHANH
            </h3>
            <p className="text-gray-600">
              Thời gian giao hàng nhanh chóng và linh hoạt bằng nhiều phương
              thức
            </p>
          </div>

          {/* Hỗ Trợ 24/7 */}
          <div className="p-6">
            <div className="flex justify-center mb-4">
              <div className="border-2 border-custom-purple p-4 rounded-full">
                <FaHeadset className="text-custom-purple text-4xl" />{" "}
                {/* Biểu tượng */}
              </div>
            </div>
            <h3 className="text-custom-purple font-bold text-lg mb-2">
              HỖ TRỢ 24/7
            </h3>
            <p className="text-gray-600">
              Hotline: 090 828 79 66 tiếp nhận thông tin và phản hồi nhanh chóng
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CommitmentSection;
