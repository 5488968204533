import React, { useState } from "react";
import { FiMenu, FiX } from "react-icons/fi";
import { Link } from "react-router-dom";

const Header: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className="bg-white border-b border-custom-purple font-roboto relative z-50">
      <div className="container mx-auto flex justify-between items-center py-4 px-4 md:px-8">
        {/* Logo section */}
        <div className="flex items-center space-x-4">
          <Link to="/">
            <img src="/logo-website.png" alt="logo" className="h-12" />
          </Link>
        </div>

        {/* Navigation section */}
        <nav className="hidden md:flex flex-1 justify-center space-x-4 lg:space-x-8 font-semibold">
          <Link
            to="/"
            className="text-custom-purple relative no-underline hover:after:content-[''] hover:after:absolute hover:after:w-full hover:after:h-[3px] hover:after:bg-custom-purple hover:after:left-0 hover:after:bottom-[-2px]"
          >
            TRANG CHỦ
          </Link>
          <Link
            to="/gioi-thieu-ve-truong-thinh-chemicals"
            className="text-custom-purple relative no-underline hover:after:content-[''] hover:after:absolute hover:after:w-full hover:after:h-[3px] hover:after:bg-custom-purple hover:after:left-0 hover:after:bottom-[-2px]"
          >
            GIỚI THIỆU
          </Link>
          <Link
            to="/san-pham"
            className="text-custom-purple relative no-underline hover:after:content-[''] hover:after:absolute hover:after:w-full hover:after:h-[3px] hover:after:bg-custom-purple hover:after:left-0 hover:after:bottom-[-2px]"
          >
            SẢN PHẨM
          </Link>
          <Link
            to="/nghien-cuu-phat-trien"
            className="text-custom-purple relative no-underline hover:after:content-[''] hover:after:absolute hover:after:w-full hover:after:h-[3px] hover:after:bg-custom-purple hover:after:left-0 hover:after:bottom-[-2px]"
          >
            R & D
          </Link>
          <Link
            to="/bai-viet"
            className="text-custom-purple relative no-underline hover:after:content-[''] hover:after:absolute hover:after:w-full hover:after:h-[3px] hover:after:left-0 hover:after:bottom-[-2px]"
          >
            BÀI VIẾT
          </Link>
        </nav>

        {/* Mobile menu button */}
        <button
          className="md:hidden text-custom-purple p-2 rounded-lg"
          onClick={toggleMenu}
        >
          {menuOpen ? (
            <FiX className="h-5 w-5" />
          ) : (
            <FiMenu className="h-5 w-5" />
          )}
        </button>

        {/* Mobile menu */}
        {menuOpen && (
          <div className="fixed top-0 left-0 w-1/2 h-screen bg-white text-custom-purple p-10 transform transition-transform duration-300 ease-in-out z-50">
            <nav className="flex flex-col space-y-6">
              <Link
                to="/"
                className="hover:underline font-bold"
                onClick={toggleMenu}
              >
                TRANG CHỦ
              </Link>
              <Link
                to="/gioi-thieu-ve-truong-thinh-chemicals"
                className="hover:underline font-bold"
                onClick={toggleMenu}
              >
                GIỚI THIỆU
              </Link>
              <Link
                to="/san-pham"
                className="hover:underline font-bold"
                onClick={toggleMenu}
              >
                SẢN PHẨM
              </Link>
              <Link
                to="/nghien-cuu-phat-trien"
                className="hover:underline font-bold"
                onClick={toggleMenu}
              >
                R & D
              </Link>
              <Link
                to="/bai-viet"
                className="hover:underline font-bold"
                onClick={toggleMenu}
              >
                BÀI VIẾT
              </Link>
              {/* Mobile Contact Button */}
              <Link to="/lien-he" onClick={toggleMenu}>
                <button className="bg-red-600 text-white px-4 py-2 rounded-full w-full mt-4">
                  LIÊN HỆ
                </button>
              </Link>
            </nav>
          </div>
        )}

        {/* Overlay for mobile menu */}
        {menuOpen && (
          <div
            className="fixed inset-0 bg-gray-800 opacity-50 z-40"
            onClick={toggleMenu}
          ></div>
        )}

        {/* Desktop Contact Button */}
        <div className="hidden md:flex items-center space-x-4">
          <Link to="/lien-he">
            <button className="bg-red-600 text-white px-4 py-2 rounded-full">
              LIÊN HỆ
            </button>
          </Link>
        </div>
      </div>
    </header>
  );
};

export default Header;
